import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HubspotDealsQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type HubspotDealsQuery = { __typename?: 'Query', hubspotDeals: Array<{ __typename?: 'HubspotDeal', dealId: string, name: string }> };


export const HubspotDealsDocument = gql`
    query hubspotDeals($search: String) {
  hubspotDeals(search: $search) {
    dealId
    name
  }
}
    `;

/**
 * __useHubspotDealsQuery__
 *
 * To run a query within a React component, call `useHubspotDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHubspotDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHubspotDealsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useHubspotDealsQuery(baseOptions?: Apollo.QueryHookOptions<HubspotDealsQuery, HubspotDealsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HubspotDealsQuery, HubspotDealsQueryVariables>(HubspotDealsDocument, options);
      }
export function useHubspotDealsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HubspotDealsQuery, HubspotDealsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HubspotDealsQuery, HubspotDealsQueryVariables>(HubspotDealsDocument, options);
        }
export function useHubspotDealsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HubspotDealsQuery, HubspotDealsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HubspotDealsQuery, HubspotDealsQueryVariables>(HubspotDealsDocument, options);
        }
export type HubspotDealsQueryHookResult = ReturnType<typeof useHubspotDealsQuery>;
export type HubspotDealsLazyQueryHookResult = ReturnType<typeof useHubspotDealsLazyQuery>;
export type HubspotDealsSuspenseQueryHookResult = ReturnType<typeof useHubspotDealsSuspenseQuery>;
export type HubspotDealsQueryResult = Apollo.QueryResult<HubspotDealsQuery, HubspotDealsQueryVariables>;