import { forwardRef, useImperativeHandle, useState } from "react";

import type { Campaign } from "@relatable/gql/generated-base";
import { Checkbox } from "@relatable/ui/Checkbox";
import { Note } from "@relatable/ui/Note";
import { MultipleSelect, Select } from "@relatable/ui/Select";
import { TextInput } from "@relatable/ui/TextInput";

import { ListSelector } from "components/ListSelector";
import { ToggleButtonGroup } from "components/ui/ToggleButtonGroup";
import { LANGUAGES } from "lib/constants";
import { useVerticalsQuery } from "modules/generated";

import { useMarketsQuery } from "@relatable/gql/generated";
import { Section } from "../Section";
import { getChangedFields } from "../helpers";
import {
  type CampaignDetailsQuery,
  useCampaignDetailsQuery,
  useUpdateCampaignMutation
} from "./generated";
import type { SectionRef } from "./types";

export type MainFields = Pick<
  Campaign,
  | "open_to_join"
  | "language"
  | "campaign_client"
  | "hashtags"
  | "case_sensitive_tags"
  | "logistics_enabled"
  | "list"
  | "campaign_owner"
> & { market_ids?: number[]; vertical_ids?: number[] };

const getInitialData = (c: CampaignDetailsQuery["campaigns"][number]): MainFields => {
  if (typeof c.market_ids === "string") throw Error("market_ids should be number[]");
  if (typeof c.vertical_ids === "string") throw Error("market_ids should be number[]");

  return {
    campaign_client: c.campaign_client,
    campaign_owner: c.campaign_owner,
    case_sensitive_tags: c.case_sensitive_tags,
    hashtags: c.hashtags,
    language: c.language,
    list: c.list,
    market_ids: c.market_ids,
    vertical_ids: c.vertical_ids,
    open_to_join: c.open_to_join,
    logistics_enabled: c.logistics_enabled
  };
};

export const MainSection = forwardRef<SectionRef<MainFields>, { campaignStub: string }>(
  ({ campaignStub }, ref) => {
    const [fieldErrors, setFieldErrors] = useState<string[]>([]);

    const [fields, setFields] = useState<Partial<MainFields>>({});

    const { data } = useCampaignDetailsQuery({
      variables: { stub: campaignStub },
      notifyOnNetworkStatusChange: true,

      onCompleted: ({ campaigns }) => {
        const [c] = campaigns;
        setFields(getInitialData(c));
      }
    });
    const verticalsQuery = useVerticalsQuery();
    const marketsQuery = useMarketsQuery();

    const campaign = data?.campaigns?.[0];

    const [updateCampaignByIdMutation, updateCampaignByIdMutationOptions] =
      useUpdateCampaignMutation();

    const validate = () => {
      const errors: string[] = [];
      if (!fields.campaign_client) errors.push("Provide Brand name!");
      if (!fields.language) errors.push("Select language!");
      if (fields.hashtags?.split(" ").some(i => !(i.startsWith("@") || i.startsWith("#")))) {
        errors.push(
          "Make sure your hashtags starts with #, mentions with @ and you left an empty space between them"
        );
      }
      setFieldErrors(errors);
      return errors;
    };

    const changedFields = campaign
      ? getChangedFields({ data: fields, initialData: getInitialData(campaign) })
      : {};

    const isChanged = Boolean(campaign ? Object.values(changedFields).length : false);

    const handleUpdate = async () => {
      if (!campaign) throw new Error("missing campaign");
      if (!campaign.project) throw new Error("missing project");

      if (validate().length) return;

      const campaignId = campaign.id;

      const hashtags = fields.hashtags
        ?.replace(",", " ")
        .split(" ")
        .map(x => x.trim())
        .filter(x => Boolean(x))
        .join(" ");

      if (isChanged) {
        updateCampaignByIdMutation({
          variables: {
            id: campaignId,
            set: {
              ...changedFields,
              updated_at: new Date().toISOString(),
              hashtags: fields.case_sensitive_tags ? hashtags : hashtags?.toLowerCase(),
              market_ids: fields.market_ids?.length ? `{${fields.market_ids.join(",")}}` : "{}",
              vertical_ids: fields.vertical_ids?.length
                ? `{${fields.vertical_ids.join(",")}}`
                : "{}"
            }
          }
        });
      }
    };

    useImperativeHandle(ref, () => ({
      submit: handleUpdate,
      validate,
      getFields: () => fields,
      setFields
    }));

    const handleFieldChange = (partialData: Partial<MainFields>) => {
      setFields(p => ({ ...p, ...partialData }));
    };

    return (
      <Section
        title={`${campaign?.title || "Loading…"}`}
        isChanged={isChanged}
        fieldErrors={fieldErrors}
        updated_at={campaign?.updated_at}
        submitError={updateCampaignByIdMutationOptions.error}
        sidebar={
          <>
            <Note variant="info" label="Hashtags and mentions">
              Must be prefixed with # or @ and separated by space
            </Note>
            <Note variant="info" label="Logistics">
              By selecting Logistics you enable phone number in the onboarding form.
            </Note>
          </>
        }
      >
        {campaign?.project.hubspot_deals.map(deal => (
          <TextInput
            key={deal.id}
            disabled
            label="Hubspot deal ID"
            onChange={() => null}
            value={deal.hubspot_deal_id}
          />
        ))}

        <TextInput
          required
          label="Brand name"
          value={fields.campaign_client || ""}
          onChange={v => handleFieldChange({ campaign_client: v })}
        />

        <MultipleSelect
          label="Verticals"
          value={fields.vertical_ids || []}
          onChange={v => handleFieldChange({ vertical_ids: v || [] })}
          options={verticalsQuery.data?.vertical.map(v => ({ label: v.name, value: v.id })) || []}
        />

        <MultipleSelect
          label="Markets"
          value={fields.market_ids || []}
          onChange={v => handleFieldChange({ market_ids: v || [] })}
          options={marketsQuery.data?.market.map(v => ({ label: v.name, value: v.id })) || []}
        />

        <TextInput
          label="Hashtags and mentions"
          value={fields.hashtags || ""}
          onChange={v => handleFieldChange({ hashtags: v })}
        />

        <ListSelector
          platform={campaign?.platform ?? undefined}
          label="Curation list"
          listId={fields.list ?? null}
          onChange={listId => handleFieldChange({ list: listId })}
          inputStyle={{ padding: "16px 8px" }}
        />

        <Select
          hideNone
          label="Campaign owner"
          value={fields.campaign_owner}
          options={
            campaign?.project?.project_team_members.map(m => ({
              label: m.account.full_name || "",
              value: m.account.id
            })) || []
          }
          onChange={v => handleFieldChange({ campaign_owner: v })}
        />

        <ToggleButtonGroup
          label="Campaign language"
          value={[fields.language || ""]}
          exclusive
          options={[
            { value: LANGUAGES.ENGLISH, content: "English" },
            { value: LANGUAGES.SWEDISH, content: "Swedish" }
          ]}
          onChange={v => handleFieldChange({ language: v[0] })}
        />

        <Checkbox
          label="Case sensitive tags"
          checked={Boolean(fields.case_sensitive_tags)}
          onChange={v => handleFieldChange({ case_sensitive_tags: v })}
        />
        <Checkbox
          label="Logistics"
          checked={Boolean(fields.logistics_enabled)}
          onChange={v => handleFieldChange({ logistics_enabled: v })}
        />
      </Section>
    );
  }
);
