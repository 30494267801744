import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { YYYY_MM_DD, prettifyDate, prettifyNumber, sum } from "@relatable/helpers";
import { getUserLabel } from "@relatable/helpers/user";
import { DateInput } from "@relatable/ui/DateInput";
import { type AddFields, Table, type TableColumn } from "@relatable/ui/Table";
import { theme } from "@relatable/ui/Theme";
import { Link } from "react-router-dom";

import { Menu } from "components/ui/Menu";
import { Popup } from "components/ui/Popup";
import { useUpdateCampaignUserMutation } from "modules/campaign/generated";
import type { UserCustomData } from "src/typings/global";

import { CreditCard } from "@mui/icons-material";
import { EditNotesPopup } from "./EditNotesPopup";
import { PaymentsDocument, type PaymentsQuery } from "./generated";

const LinkBox = styled.div`
  a:hover {
    text-decoration: underline;
  }
`;

export const PaymentsTable: FC<{
  loading: boolean;
  users: PaymentsQuery["campaign_user"];
}> = ({ loading, users }) => {
  const [updateUser] = useUpdateCampaignUserMutation({
    awaitRefetchQueries: true,
    refetchQueries: [PaymentsDocument]
  });
  const [editNotesCampaignUserId, setEditNotesCampaignUserId] = useState<number | null>(null);

  const [accountingDatePicker, setAccountingDatePicker] = useState<{
    type: "full" | "half";
    campaignUserId;
  } | null>(null);
  const [accountedAt, setAccountedAt] = useState(new Date().toISOString());

  const columns: TableColumn<typeof rows>[] = [
    // needed for Nini
    {
      field: "daysPast",
      headerName: "Days",

      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      field: "userLabel",
      headerName: "Username",
      minWidth: 150,
      renderCell: ({ value, row }) => <Link to={`/user/${row.userId}`}>{value}</Link>
    },
    {
      field: "campaignTitle",
      headerName: "Campaign",

      renderCell: ({ value, row }) => (
        <LinkBox>
          <Link to={`/campaign/${row.campaignStub}`}>{value}</Link>
          <Link style={{ marginLeft: 5 }} to={`/campaign/${row.campaignStub}/expenses`}>
            <CreditCard />
          </Link>
        </LinkBox>
      )
    },
    {
      field: "hubspotIds",
      minWidth: 95,
      headerName: "Hubspot"
    },
    {
      field: "invoice_sek",
      headerName: "PaySek"
    },
    {
      field: "invoice",
      headerName: "Invoice"
    },
    {
      field: "currency",
      headerName: "Currency"
    },
    {
      minWidth: 170,
      field: "gigapay_status",
      headerName: "Status",
      useRenderCellForExport: true,
      renderCell: ({ value, row }) => {
        const suffix = row.paymentMethod ? ` (${row.paymentMethod})` : "";

        if (row.paymentState !== "GIGA_PAY") {
          switch (row.paymentState) {
            case "CUSTOM_PAYMENT":
              return `To be paid manually ${suffix}`;

            case "CUSTOM_PAYMENT_PAID":
              return (
                <span style={{ color: theme.palette.success.main }}>Paid manually {suffix}</span>
              );

            default:
              return (
                <>
                  {row.paymentState}
                  {suffix}
                </>
              );
          }
        }

        if (!value) {
          if (suffix) return suffix.trim();
          return "-";
        }

        let humanizedValue = value.toLowerCase().replace(/_/g, " ");
        humanizedValue = humanizedValue.replace(humanizedValue[0], humanizedValue[0].toUpperCase());
        if (value === "MONEY_SENT") {
          return (
            <>
              <span style={{ color: theme.palette.success.main }}>{humanizedValue}</span>
              {suffix}
            </>
          );
        }

        return (
          <span>
            {humanizedValue}
            {suffix}
          </span>
        );
      }
    },

    {
      minWidth: 100,
      field: "expenses",
      headerName: "Exp.",
      renderCell: ({ value, row }) => {
        if (row.expensesPaidLength === row.expensesTotalLength) {
          return <span style={{ color: theme.palette.success.main }}>{value}</span>;
        }

        if (row.gigapay_status === "MONEY_SENT" || row.paymentState === "CUSTOM_PAYMENT_PAID") {
          return <span style={{ color: theme.palette.warning.main }}>{value}</span>;
        }

        return value;
      }
    },
    {
      minWidth: 100,
      field: "expensesSum",
      headerName: "Exp.sum",
      renderCell: ({ value, row }) => {
        if (row.expensesPaidLength === row.expensesTotalLength) {
          return <span style={{ color: theme.palette.success.main }}>{value}</span>;
        }

        if (row.gigapay_status === "MONEY_SENT" || row.paymentState === "CUSTOM_PAYMENT_PAID") {
          return <span style={{ color: theme.palette.warning.main }}>{value}</span>;
        }

        return value;
      }
    },
    {
      field: "accountedAt",
      headerName: "Accounted",
      useRenderCellForExport: true,
      renderCell: ({ row }) => {
        if (row.accountedAt) return `Accounted at ${prettifyDate(row.accountedAt)}`;
        if (row.halfAccountedAt) return `Half accounted at ${prettifyDate(row.halfAccountedAt)}`;
        return "-";
      }
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: ({ row }) => {
        const paidByGigaPay =
          row.gigapay_status === "HALF_PAYMENT_REQUESTED" ||
          row.gigapay_status === "MONEY_SENT" ||
          row.gigapay_status === "PAYMENT_REQUESTED";
        return (
          <Menu
            options={[
              {
                label: "Go to expenses",
                href: `/campaign/${row.campaignStub}/expenses`
              },
              {
                label: "Edit payment notes",
                onClick: () => setEditNotesCampaignUserId(row.campaignUserId),
                divider: true
              },
              {
                label: "Set manual payment",
                variant:
                  row.paymentState === "CUSTOM_PAYMENT"
                    ? "disabled"
                    : paidByGigaPay
                      ? "danger"
                      : undefined,
                onClick: () =>
                  updateUser({
                    variables: {
                      campaignUserId: row.campaignUserId,
                      _set: { payment_state: "CUSTOM_PAYMENT" }
                    }
                  })
              },
              {
                label: "Set manual payment paid",
                variant:
                  row.paymentState === "CUSTOM_PAYMENT_PAID"
                    ? "disabled"
                    : paidByGigaPay
                      ? "danger"
                      : undefined,
                divider: true,
                onClick: () =>
                  updateUser({
                    variables: {
                      campaignUserId: row.campaignUserId,
                      _set: { payment_state: "CUSTOM_PAYMENT_PAID" }
                    }
                  })
              },
              {
                label: "Set GigaPay payment",
                variant:
                  row.paymentState === "CUSTOM_PAYMENT_PAID" || row.paymentState === "GIGA_PAY"
                    ? "disabled"
                    : undefined,
                onClick: () =>
                  updateUser({
                    variables: {
                      campaignUserId: row.campaignUserId,
                      _set: { payment_state: "GIGA_PAY" }
                    }
                  })
              },
              {
                label: row.halfAccountedAt ? "Redo set half accounted" : "Set half accounted",
                variant:
                  row.gigapay_status !== "HALF_PAYMENT_REQUESTED" || row.accountedAt
                    ? "disabled"
                    : undefined,
                onClick: () => {
                  if (row.halfAccountedAt) {
                    updateUser({
                      variables: {
                        campaignUserId: row.campaignUserId,
                        _set: {
                          half_accounted_at: null
                        }
                      }
                    });
                    return;
                  }
                  setAccountingDatePicker({ type: "half", campaignUserId: row.campaignUserId });
                }
              },
              {
                label: row.accountedAt ? "Redo set accounted" : "Set accounted",
                variant: row.gigapay_status !== "PAYMENT_REQUESTED" ? "disabled" : undefined,
                onClick: () => {
                  if (row.accountedAt) {
                    updateUser({
                      variables: {
                        campaignUserId: row.campaignUserId,
                        _set: { accounted_at: null }
                      }
                    });
                    return;
                  }
                  setAccountingDatePicker({ type: "full", campaignUserId: row.campaignUserId });
                }
              }
            ]}
          />
        );
      }
    }
  ];

  const rows = users?.map(cu => {
    const expensesPaidLength = cu.campaign_expenses.filter(
      ce =>
        ce.payment_state === "CUSTOM_PAYMENT_PAID" ||
        (ce.payment_state === "GIGA_PAY" && ce.gigapay_payment_id)
    ).length;
    const expensesTotalLength = cu.campaign_expenses.length;
    const r = {
      key: `${cu.id}-${cu.campaign.id}`,
      campaignUserId: cu.id,
      userId: cu.user?.id,
      accountedAt: cu.accounted_at,
      halfAccountedAt: cu.half_accounted_at,
      expenses: expensesTotalLength ? `${expensesPaidLength} / ${expensesTotalLength}` : "-",
      expensesSum: expensesTotalLength
        ? `${sum(cu.campaign_expenses.map(e => e.amount || 0))} ${cu.currency}`
        : "-",
      expensesPaidLength,
      expensesTotalLength,
      userLabel: getUserLabel(cu.user ?? {}),
      daysPast: cu.campaign_user_days_since_completed?.days_since_completed,
      paymentMethod: (cu.user?.legacy_custom_data as UserCustomData)?.payments?.paymentoptions
        ?.choice,
      hubspotIds: cu.campaign.project.hubspot_deals.map(d => d.hubspot_deal_id).join(", "),
      campaignStub: cu.campaign.stub,
      campaignTitle: cu.campaign.title,
      invoice_sek: prettifyNumber(
        ((typeof cu.local_invoice_pay === "number"
          ? Math.ceil(cu.local_invoice_pay)
          : cu.local_invoice_pay) || 0) / (cu.campaign.exchange_rate || 1)
      ),
      currency: cu.currency,
      invoice: prettifyNumber(
        typeof cu.local_invoice_pay === "number"
          ? Math.ceil(cu.local_invoice_pay)
          : cu.local_invoice_pay
      ),
      paymentState: cu.payment_state,
      gigapay_status: cu.gigapay_status
    };
    return r as AddFields<typeof r, "actions">;
  });

  return (
    <>
      <Table
        tableId="payments"
        canExportCSV
        sortable
        containerStyles={{ maxHeight: "none" }}
        loading={loading}
        columns={columns}
        rows={rows || []}
      />
      {editNotesCampaignUserId && (
        <EditNotesPopup
          onClose={() => setEditNotesCampaignUserId(null)}
          campaignUserId={editNotesCampaignUserId}
        />
      )}
      {accountingDatePicker && (
        <Popup
          title={accountingDatePicker.type === "full" ? "Accounting" : "Half accounting"}
          actionLabel={
            accountingDatePicker.type === "full" ? "Set as Accounted" : "Set as Half accounted"
          }
          onClose={() => setAccountingDatePicker(null)}
          onAction={() =>
            updateUser({
              variables: {
                campaignUserId: accountingDatePicker.campaignUserId,
                _set:
                  accountingDatePicker.type === "full"
                    ? {
                        accounted_at: accountedAt
                      }
                    : { half_accounted_at: accountedAt }
              }
            }).then(() => setAccountingDatePicker(null))
          }
        >
          <DateInput value={YYYY_MM_DD(accountedAt)} onChange={v => setAccountedAt(v)} />
        </Popup>
      )}
    </>
  );
};
