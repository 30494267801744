import { type CSSProperties, type ReactElement, useMemo } from "react";

import { Autocomplete as AutocompleteMui, debounce } from "@mui/material";
import { TextInput } from "@relatable/ui/TextInput";

export const Autocomplete = <T extends string | number>({
  options,
  value,
  label,
  style,
  onChange,
  isDisabled,
  onInputChange,
  inputValue
}: {
  options: { label: string; id: T }[];
  value: T | undefined | null;
  label: string;
  style?: CSSProperties;
  isDisabled?: boolean;
  onChange(o: T | null): void;
  onInputChange?(e: string): void;
  inputValue?: string;
}): ReactElement => {
  const debouncedOnInputChange = useMemo(
    () =>
      debounce((t: string) => {
        onInputChange?.(t);
      }, 1000),
    [onInputChange]
  );
  const foundValue = (options.find(o => String(o.id) === String(value)) || null) as any;
  return (
    <AutocompleteMui
      disabled={isDisabled}
      style={style}
      openOnFocus
      options={options}
      // fallback to empty string to not avoid changing uncontrolled input to controlled
      defaultValue={foundValue}
      onChange={(_event, newValue) => {
        const id: T = typeof value === "number" ? Number(newValue?.id) : newValue?.id;
        onChange(id || null);
      }}
      renderInput={params => (
        <TextInput onChange={debouncedOnInputChange} {...params} label={label} />
      )}
    />
  );
};
