import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { getPlatformLabel } from "@relatable/gql";
import type { Platform_Enum, Project } from "@relatable/gql/generated-base";
import { CONTENT_RIGHT_PERIODS } from "@relatable/helpers/constants";
import { Note } from "@relatable/ui/Note";
import { MultipleSelect, Select } from "@relatable/ui/Select";
import { TextInput } from "@relatable/ui/TextInput";

import { useMarketsQuery } from "@relatable/gql/generated";
import { ToggleButtonGroup } from "components/ui/ToggleButtonGroup";
import { CONTENT_RIGHT_TYPES, PLATFORMS, PROJECT_TYPES } from "lib/constants";

import { Section } from "../Section";
import { type ProjectDetailsQuery, useUpdateProjectMutation } from "../generated";
import { getChangedFields } from "../helpers";
import type { SectionRef } from "./types";

type Fields = Pick<
  Project,
  "target_reach" | "organic_usage_period" | "content_right_type" | "content_right_period"
> & {
  goals: string[];
  market_ids: number[];
};

export const GoalsSection = forwardRef<
  SectionRef,
  {
    project: ProjectDetailsQuery["projects"][number] | undefined;
    isSubmitting: boolean;
    platforms: Platform_Enum[];
    onPlatformsChange(v: Platform_Enum[]): void;
    targetParticipantsMin: number | null;
    targetParticipantsMax: number | null;
    onTargetParticipantsMinChange(v: number): void;
    onTargetParticipantsMaxChange(v: number): void;
  }
>(
  (
    {
      project,
      isSubmitting,
      platforms,
      onPlatformsChange,
      targetParticipantsMax,
      targetParticipantsMin,
      onTargetParticipantsMaxChange,
      onTargetParticipantsMinChange
    },
    ref
  ) => {
    const [fieldErrors, setFieldErrors] = useState<string[]>([]);
    const [fields, setFields] = useState<Partial<Fields>>({});

    const markets = useMarketsQuery();

    const getInitialData = (p: ProjectDetailsQuery["projects"][number]): Fields => {
      if (typeof p.market_ids === "string") throw new Error("market_ids should be number[]");
      if (typeof p.goals === "string") throw new Error("goals should be string[]");
      return {
        target_reach: p.target_reach,
        organic_usage_period: p.organic_usage_period,
        goals: p.goals || [],
        market_ids: p.market_ids,
        content_right_period: p.content_right_period,
        content_right_type: p.content_right_type || ""
      };
    };

    useEffect(() => {
      if (!project) return;
      setFields(getInitialData(project));
    }, [project]);

    const [updateProject, updateProjectOptions] = useUpdateProjectMutation();

    const changedFields = getChangedFields({
      data: {
        ...fields,
        target_participants_min: targetParticipantsMin,
        target_participants_max: targetParticipantsMax
      },
      initialData: project
        ? {
            ...getInitialData(project),
            target_participants_min: project.target_participants_min || null,
            target_participants_max: project.target_participants_max || null
          }
        : project
    });

    const hasPlatformsChanged =
      platforms.length !== (project?.platforms ?? []).length ||
      platforms.some(p => !(project?.platforms ?? []).includes(p));

    const isChanged = project
      ? Object.values(changedFields).length > 0 || hasPlatformsChanged
      : false;

    const validate = () => {
      const errors: string[] = [];
      setFieldErrors(errors);

      return errors;
    };

    const handleUpdate = async () => {
      if (!project || !isChanged) return;
      if (validate().length) return;

      const promises: Promise<unknown>[] = [];

      await Promise.all([
        (Object.values(changedFields).length || hasPlatformsChanged) &&
          updateProject({
            variables: {
              projectId: project.id,
              set: {
                ...changedFields,
                updated_at: new Date().toISOString(),
                goals: fields.goals?.length ? `{${fields.goals.join(",")}}` : null,
                market_ids: `{${(fields.market_ids || []).join(",")}}`,
                platforms: platforms?.length ? `{${platforms.join(",")}}` : null
              }
            }
          }),
        ...promises
      ]);
    };

    const handleFieldChange = (partialData: Partial<Fields>) => {
      setFields({ ...fields, ...partialData });
    };

    const isLoading = updateProjectOptions.loading || isSubmitting || !project;

    useImperativeHandle(ref, () => ({
      submit: handleUpdate,
      validate
    }));

    return (
      <Section
        title="Goals"
        isChanged={isChanged}
        fieldErrors={fieldErrors}
        submitError={updateProjectOptions.error}
        updatedAtNotImplemented
        updated_at={undefined}
        sidebar={
          <Note variant="info" label="What the creator will see in the agreement">
            <ul style={{ padding: 0 }}>
              <li>
                <b>Organic usage only:</b> It is OK for our client to use your content created for
                this campaign in their own channels provided that they refer to you as the content
                creator and publish it without any paid media.
              </li>
              <li>
                <b>Global digital rights:</b> It is OK for our client to use your content created
                for this campaign for all types of digital marketing.
              </li>
              <li>
                <b>Ads:</b>
                It is OK for us and our client to use your content created for this campaign for Ads
                through the Facebook Ads Manager.
              </li>
              <li>
                <b>Full commercial rights:</b> It is OK for our client to use the content created
                for this campaign for all types of marketing.
              </li>
            </ul>
          </Note>
        }
      >
        <ToggleButtonGroup
          label="Project objective"
          disabled={isLoading}
          value={fields.goals || []}
          options={Object.values(PROJECT_TYPES).map(i => ({ content: i.label, value: i.value }))}
          onChange={v => handleFieldChange({ goals: v })}
        />
        <ToggleButtonGroup
          label="Platforms"
          disabled={isLoading}
          value={platforms}
          options={PLATFORMS.map(i => ({ content: getPlatformLabel(i), value: i }))}
          onChange={v => onPlatformsChange(v as Platform_Enum[])}
        />
        <Select
          label="Content rights type"
          disabled={isLoading}
          value={fields.content_right_type || null}
          onChange={type => handleFieldChange({ content_right_type: type })}
          options={Object.values(CONTENT_RIGHT_TYPES).filter(
            i =>
              fields.content_right_type === "organic_usage_only" || i.value !== "organic_usage_only"
          )}
        />
        <Select
          hideNone
          required
          label="Organic usage period"
          disabled={isLoading}
          value={fields?.organic_usage_period}
          options={Object.values(CONTENT_RIGHT_PERIODS).filter(i => i.value !== "hide")}
          onChange={v => v && handleFieldChange({ organic_usage_period: v })}
        />
        <Select
          hideNone
          label="Content rights period"
          disabled={isLoading || !fields.content_right_type}
          value={fields.content_right_type ? fields.content_right_period : null}
          options={Object.values(CONTENT_RIGHT_PERIODS).filter(i => i.value !== "hide")}
          onChange={period => period && handleFieldChange({ content_right_period: period })}
        />
        <MultipleSelect
          label="Markets"
          disabled={isLoading}
          style={{ flexGrow: 1 }}
          value={fields.market_ids || []}
          options={markets.data?.market.map(m => ({ label: m.name, value: m.id })) || []}
          onChange={v => handleFieldChange({ market_ids: v || [] })}
        />
        <TextInput
          disabled={isLoading}
          label="Target reach"
          type="number"
          value={String(fields.target_reach || "")}
          onChange={v => handleFieldChange({ target_reach: Number(v) })}
        />

        <TextInput
          label="Target min participants"
          type="number"
          disabled={isLoading}
          value={String(targetParticipantsMin || "")}
          onChange={v => onTargetParticipantsMinChange(Number(v))}
        />
        <TextInput
          label="Target max participants"
          type="number"
          disabled={isLoading}
          value={String(targetParticipantsMax || "")}
          onChange={v => onTargetParticipantsMaxChange(Number(v))}
        />
      </Section>
    );
  }
);
