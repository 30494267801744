import type { Campaign_User_Bool_Exp } from "@relatable/gql/generated-base";

import { usePaymentsQuery } from "./generated";

export const usePayments = ({
  search,
  includePaid,
  show2years
}: {
  search: string;
  includePaid: boolean;
  show2years: boolean;
}) => {
  const searchCondition: Campaign_User_Bool_Exp = search
    ? {
        ...(Number.isFinite(Number(search))
          ? {
              campaign: {
                project: { hubspot_deals: { hubspot_deal_id: { _eq: Number(search) as any } } }
              }
            }
          : {
              _or: [
                {
                  campaign: { title: { _ilike: `%${search}%` } }
                },
                {
                  user: {
                    _or: [
                      { user_instagram: { username: { _ilike: `%${search}%` } } },
                      { user_tiktok: { username: { _ilike: `%${search}%` } } },
                      { user_snapchat: { username: { _ilike: `%${search}%` } } },
                      { user_youtube: { channel_title: { _ilike: `%${search}%` } } },
                      { user_youtube: { username: { _ilike: `%${search}%` } } }
                    ]
                  }
                }
              ]
            })
      }
    : {};

  const MAX_PER_PAGE = 1500;

  const { data, loading, fetchMore } = usePaymentsQuery({
    variables: {
      offset: 0,
      limit: MAX_PER_PAGE,
      orderBy: { campaign_user_days_since_completed: { days_since_completed: "asc" }, id: "desc" },
      where: {
        campaign_user_days_since_completed: {
          days_since_completed: { _lte: show2years ? 730 : 365 }
        },
        state: { _in: ["approved", "confirmed"] },
        _and: [
          searchCondition,
          includePaid
            ? {}
            : {
                _or: [
                  {
                    payment_state: { _neq: "CUSTOM_PAYMENT_PAID" },
                    gigapay_status: { _neq: "MONEY_SENT" },
                    accounted_at: { _is_null: true }
                  },
                  {
                    payment_state: { _neq: "CUSTOM_PAYMENT_PAID" },
                    gigapay_status: { _is_null: true },
                    accounted_at: { _is_null: true }
                  },
                  { campaign_expenses: { payment_state: { _neq: "CUSTOM_PAYMENT_PAID" } } }
                ]
              }
        ]
      }
    }
  });

  const hasMore = Boolean(
    data && data.campaign_user.length !== 0 && data.campaign_user.length % MAX_PER_PAGE === 0
  );

  return {
    users: data?.campaign_user || [],
    loading,
    hasMore,
    fetchMore: () => {
      if (!hasMore) return;
      fetchMore({
        variables: { offset: data?.campaign_user.length },
        updateQuery: (prev, { fetchMoreResult }) => ({
          campaign_user: [...prev.campaign_user, ...fetchMoreResult.campaign_user]
        })
      });
    }
  };
};
