import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";

import type { ApolloError } from "@apollo/client";
import type { Project, Project_Invoicing_Detail } from "@relatable/gql/generated-base";
import { Button } from "@relatable/ui/Button";
import { Checkbox } from "@relatable/ui/Checkbox";
import { Note } from "@relatable/ui/Note";
import { Select } from "@relatable/ui/Select";
import { TextInput } from "@relatable/ui/TextInput";
import { Autocomplete } from "components/ui/Autocomplete";
import { CLIENT_RATINGS, CURRENCIES } from "lib/constants";
import { Link } from "react-router-dom";
import { ExchangeRate } from "../ExchangeRate";
import type { SectionRef } from "../ProjectUpdate/types";
import { Section, SubSection } from "../Section";
import { type ProjectDetailsQuery, useDealInformationQuery } from "../generated";
import { getChangedFields } from "../helpers";
import { HubspotSection } from "./HubspotSection";

type Fields = Pick<
  Project,
  | "project_owner_id"
  | "name"
  | "proposal_url"
  | "agreement_url"
  | "client_rating"
  | "agreement_attachment_url"
  | "agreement_attachment_title"
  | "can_use_for_marketing"
>;

type InvoicingFields = Pick<Project_Invoicing_Detail, "exchange_rate" | "currency">;

export const MetaSection = forwardRef<
  SectionRef,
  {
    onSubmit(data: Partial<Fields & InvoicingFields & { hubspotDealIds: string[] }>): Promise<void>;
    submitError?: ApolloError;
    isSubmitting: boolean;
    variant: "create" | "update";
    project?: Partial<ProjectDetailsQuery["projects"][number]>;
  }
>(({ onSubmit, submitError, isSubmitting, variant, project }, ref) => {
  const [fieldErrors, setFieldErrors] = useState<string[]>([]);
  const [fields, setFields] = useState<Partial<Fields>>({
    client_rating:
      variant === "create"
        ? CLIENT_RATINGS.find(rating => rating.label === "1🌟 key client")?.value || null
        : null
  });
  const [invoicingFields, setInvoicingFields] = useState<InvoicingFields>({
    currency: "",
    exchange_rate: 0
  });
  const [hubspotDealIds, setHubspotDealIds] = useState<string[]>([]);

  useEffect(() => {
    if (!project) return;
    setFields({
      agreement_url: project.agreement_url,
      client_rating: project.client_rating,
      name: project.name,
      project_owner_id: project.project_owner_id,
      proposal_url: project.proposal_url,
      agreement_attachment_url: project.agreement_attachment_url,
      agreement_attachment_title: project.agreement_attachment_title || "Appendix C",
      can_use_for_marketing: project.can_use_for_marketing
    });
    setInvoicingFields({
      currency: project.project_invoicing_detail?.currency || "",
      exchange_rate: project.project_invoicing_detail?.exchange_rate || 0
    });
    setHubspotDealIds(project.hubspot_deals?.map(i => i.hubspot_deal_id) || []);
  }, [project]);

  const {
    data: { accounts = [] } = {},
    loading: isLoadingOptions
  } = useDealInformationQuery();

  const validate = () => {
    const errors: string[] = [];
    if (!fields.project_owner_id) errors.push("Select sales rep!");
    if (!hubspotDealIds?.length) errors.push("Select Hubspot deal name!");
    if (!fields.name) errors.push("Provide project name!");
    if (!fields.client_rating) errors.push("Select client score!");
    if (!invoicingFields.currency) errors.push("Select currency!");
    if (!invoicingFields.exchange_rate) {
      errors.push("An error with an exchange rate, refresh the page and pick the currency");
    }
    setFieldErrors(errors);
    return errors;
  };

  const haveHubspotDealsChanged = Boolean(
    project &&
      project?.hubspot_deals
        ?.map(i => i.hubspot_deal_id)
        .sort()
        .join() !== hubspotDealIds.sort().join()
  );
  const changedFields =
    variant === "create"
      ? { ...fields, ...invoicingFields }
      : getChangedFields({
          data: fields,
          initialData: project as ProjectDetailsQuery["projects"][number]
        });

  const handleSubmit = async () => {
    if (validate().length) return;
    if (!Object.values(changedFields).length && !haveHubspotDealsChanged) return;

    const data: Partial<Fields & InvoicingFields & { hubspotDealIds: string[] }> = changedFields;

    if (changedFields.agreement_attachment_url) {
      const url = new URL(changedFields.agreement_attachment_url);
      data.agreement_attachment_url = url.origin + url.pathname;
    }

    if (haveHubspotDealsChanged) {
      data.hubspotDealIds = hubspotDealIds;
    }

    await onSubmit(data);
  };
  const handleFieldChange = (partialData: Partial<Fields>) => {
    setFields({ ...fields, ...partialData });
  };

  const isLoading = isLoadingOptions || isSubmitting || !project;
  const isChanged =
    variant === "create"
      ? false
      : Object.values(changedFields).length > 0 || haveHubspotDealsChanged;

  useImperativeHandle(ref, () => ({
    submit: handleSubmit,
    validate
  }));

  const handleOnRateChange = useCallback(
    v => {
      if (v !== invoicingFields.exchange_rate) {
        setInvoicingFields(p => ({ ...p, exchange_rate: Number(v) }));
      }
    },
    [invoicingFields.exchange_rate]
  );
  return (
    <Section
      isChanged={isChanged}
      fieldErrors={fieldErrors}
      submitError={submitError}
      title={variant === "update" ? "Deal information" : ""}
      updated_at={variant === "update" ? project?.updated_at : null}
      sidebar={
        <>
          <Note variant="info" label="Client priority">
            <Link
              to="https://docs.google.com/spreadsheets/d/181XLi10O8mETXb0EvHWBkXDsDdLJvZxJRpSo50Z85KM/edit#gid=2026408406"
              target="_blank"
              rel="noreferrer"
            >
              Identify client priority here: https://docs.google.com/spreadsheets
            </Link>
          </Note>
          {variant === "create" ? (
            <>
              <Note variant="info" label="What next?">
                Create project and add invoices, goals, budget and campaigns in the next steps.
              </Note>

              <Button
                style={{ marginTop: "auto" }}
                size="medium"
                isLoading={isLoading}
                onClick={() => handleSubmit()}
              >
                Create
              </Button>
            </>
          ) : (
            <Note variant="info" label="Currency exchange">
              Currency exchange rate as the currency can be set only when creating a project.
            </Note>
          )}
        </>
      }
    >
      <Autocomplete
        label="Sales rep *"
        isDisabled={isLoading}
        value={fields.project_owner_id}
        onChange={v => handleFieldChange({ project_owner_id: v })}
        options={accounts.map(account => ({
          id: account.id,
          label: account.full_name || ""
        }))}
      />

      <TextInput
        required
        label="Project name"
        disabled={isLoading}
        value={fields.name}
        onChange={v => handleFieldChange({ name: v })}
      />

      <SubSection>
        <Select
          required
          hideNone
          label="Client score"
          style={{ width: "100%" }}
          disabled={isLoading}
          onChange={v => handleFieldChange({ client_rating: v })}
          options={[...CLIENT_RATINGS].sort(({ value: a }, { value: b }) => b - a)}
          value={fields.client_rating}
        />
      </SubSection>

      <TextInput
        label="Link to proposal"
        disabled={isLoading}
        value={fields.proposal_url || ""}
        onChange={v => handleFieldChange({ proposal_url: v })}
      />
      <TextInput
        label="Link to agreement"
        disabled={isLoading}
        value={fields.agreement_url || ""}
        onChange={v => handleFieldChange({ agreement_url: v })}
      />

      {variant === "update" ? (
        project?.project_invoicing_detail?.exchange_rate !== 1 && (
          <TextInput
            disabled
            style={{ width: "100%" }}
            onChange={() => null}
            label="Exchange rate SEK"
            type="number"
            value={String(project?.project_invoicing_detail?.exchange_rate)}
          />
        )
      ) : (
        <ExchangeRate currency={invoicingFields.currency} onChange={handleOnRateChange} />
      )}

      <Select
        label="Currency"
        hideNone
        required
        style={{ width: "100%" }}
        disabled={variant === "update" || isLoading}
        value={invoicingFields.currency}
        options={Object.values(CURRENCIES).map(c => ({ label: c, value: c }))}
        onChange={v => v && setInvoicingFields(p => ({ ...p, currency: v }))}
      />

      <Checkbox
        label="Can be used for marketing and case studies"
        disabled={isLoading}
        checked={Boolean(fields.can_use_for_marketing)}
        onChange={v => handleFieldChange({ can_use_for_marketing: Boolean(v) })}
      />

      <HubspotSection hubspotDealIds={hubspotDealIds} onChange={setHubspotDealIds} />
    </Section>
  );
});
