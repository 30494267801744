import { type FC, useEffect } from "react";

import { useSnackbar } from "@relatable/ui/Snackbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";

import { Overview } from "./Overview";
import type { UserContentStatus } from "./common/reshape_data";
import {
  type ContentApprovalOverviewQuery,
  useNotifyClientMutation,
  useSendCampaignContentApprovalContentMediaZipOnSlackMutation
} from "./generated";
import type { Filter } from "./types";

export const OverviewContainer: FC<{
  campaign: ContentApprovalOverviewQuery["campaigns"][number] | undefined;
  usersContentStatus: UserContentStatus[];
  filteredUsersContentStatus: UserContentStatus[];
  workspaceUrls: string[];
  onSetFilters: (f: Filter) => void;
  filters: Filter;
}> = ({
  campaign,
  usersContentStatus,
  filteredUsersContentStatus,
  workspaceUrls,
  onSetFilters,
  filters
}) => {
  const { campaignStub } = useParams<{ campaignStub: string }>();
  const location = useLocation();
  const state = location.state as { reviewedContent?: { state: string } | null };
  const navigate = useNavigate();
  const [notifyClient, { loading: notifyClientLoading }] = useNotifyClientMutation({
    onCompleted: () => snackbar.success("The client was notified")
  });

  const snackbar = useSnackbar();

  useEffect(() => {
    if (state?.reviewedContent) {
      snackbar.success(`Content ${state.reviewedContent.state}`);
      navigate("", { state: { reviewedContent: null } });
    }
  }, [navigate, snackbar, state?.reviewedContent]);

  const onRowClick = ({
    userId,
    contentSettingId
  }: {
    userId: number;
    contentSettingId: number;
  }) => {
    const path = `${location.pathname}/${userId}/${contentSettingId}`;
    navigate(path);
  };

  const [sendZipOnSlack] = useSendCampaignContentApprovalContentMediaZipOnSlackMutation({
    onCompleted: () => {
      snackbar.success("Download requested, it will appear shortly in Slack");
    },
    onError: err => {
      console.error(err);
      snackbar.error(`Something went wrong…, error: ${err.message}`);
    }
  });

  if (!campaign || usersContentStatus === null) {
    return <Loader />;
  }

  return (
    <Overview
      onRowClick={onRowClick}
      onDownload={() => sendZipOnSlack({ variables: { stub: campaignStub ?? "" } })}
      platform={campaign.platform}
      usersContentStatus={usersContentStatus}
      filteredUsersContentStatus={filteredUsersContentStatus}
      workspaceUrls={workspaceUrls}
      onSetFilters={onSetFilters}
      filters={filters}
      pushNotificationToClient={() =>
        notifyClient({
          variables: {
            campaignId: campaign.id
          }
        })
      }
      notifyClientLoading={notifyClientLoading}
    />
  );
};
