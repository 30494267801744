import type { FC } from "react";

import styled from "@emotion/styled";
import { Grid, Tab, Tabs } from "@mui/material";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";

import { NavigationButton } from "components/ui/NavigationButton";
import { ContentContainer } from "lib/styled";

import { Link } from "@mui/icons-material";
import { useCampaignsQuery } from "../generated";
import { ClientListSubmissions } from "./ClientListSubmissions";
import { ClientSheetContainer } from "./ClientSheetContainer";
import { useCampaignClientSheetQuery } from "./generated";

export const ClientManager: FC = () => {
  const { pathname } = useLocation();
  const { campaignStub } = useParams<{ campaignStub: string }>();
  const navigate = useNavigate();

  const { loading: clientSheetLoading, data } = useCampaignClientSheetQuery({
    variables: { campaignStub: campaignStub ?? "" }
  });
  const clientSheetLogs = data?.campaign_client_sheet || [];
  const hasSheetLinked = clientSheetLogs.length !== 0;

  const { data: campaignsData } = useCampaignsQuery({
    variables: { where: { stub: { _eq: campaignStub } } }
  });
  const campaign = campaignsData?.campaigns[0];
  const { project } = campaign || {};
  return (
    <ContentContainer>
      <NavBar>
        <NavigationButton direction="back" pathname={`/campaign/${campaignStub}`} />
        {hasSheetLinked
          ? project?.hubspot_deals.map(deal => (
              <Button
                key={deal.id}
                icon={<Link />}
                href={`${process.env.CLIENT_PORTAL_URL}/client/${deal?.hubspot_company_id}/p/${project?.stub}/c/${campaignStub}/creators?is_admin=true`}
                target="_blank"
                className="view-creator-list"
              >
                View Creator List
                {project.hubspot_deals.length > 1 ? ` (${deal.hubspot_company_id})` : ""}
              </Button>
            ))
          : null}
      </NavBar>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          <Tabs
            value={pathname.endsWith("submissions") ? "submissions" : "sheet"}
            onChange={(e, newValue) =>
              navigate(`/campaign/${campaignStub}/manage/${newValue}`, { replace: true })
            }
          >
            <Tab value="sheet" label="Manage" />
            <Tab value="submissions" label="Client submissions" />
          </Tabs>
          <Routes>
            <Route
              path="sheet"
              element={
                campaign && (
                  <ClientSheetContainer
                    campaign={campaign}
                    sheetLogsLoading={clientSheetLoading}
                    sheetLogs={clientSheetLogs}
                  />
                )
              }
            />
            <Route
              path="submissions"
              element={
                <ClientListSubmissions loading={clientSheetLoading} sheetLogs={clientSheetLogs} />
              }
            />
            <Route path="" element={<Navigate to="sheet" />} />
          </Routes>
        </Grid>
      </Grid>
    </ContentContainer>
  );
};

const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 24px;

  > .view-creator-list {
    border: 1px solid ${palette.gray.black};
    border-radius: 5px;
    padding: 8px;
  }
`;
