import { Loader } from "@relatable/ui/Loader";
import { type FC, useState } from "react";
import { useStatsQuery } from "./generated";

export const Stats: FC = () => {
  const { data } = useStatsQuery();
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div>
      {data?.stats?.projectProgressEmbedUrl && (
        <iframe
          src={data.stats.projectProgressEmbedUrl}
          title="ProjectProgress"
          width={"100%"}
          height="600"
          style={{ border: "none" }}
          onLoad={() => setIsLoaded(true)}
        />
      )}
      {!isLoaded && <Loader />}
    </div>
  );
};
