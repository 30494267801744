import type { Clients_Bool_Exp, Platform_Enum } from "@relatable/gql/generated-base";
import { useDocumentTitle } from "@relatable/ui/hooks/useDocumentTitle";

import { Loader } from "@relatable/ui/Loader";
import { useSearchParams } from "@relatable/ui/hooks/useSearchParams";
import { useMeQuery } from "hooks/generated";
import { ContentContainer } from "lib/styled";

import { Pagination } from "@mui/material";
import { useState } from "react";
import { ClientCard } from "./ClientCard/ClientCard";
import { OverviewHeader } from "./OverviewHeader";
import { useOverviewAggregateQuery, useOverviewQuery } from "./generated";

const PER_PAGE = 5;
export const Overview: React.FC = () => {
  useDocumentTitle("Projects");

  const params = useSearchParams<{
    project: string;
    platform: Platform_Enum;
    archived: string;
    salesUnit: string;
    teamMemberAccountId: string;
    markets?: string;
    verticals?: string;
    goals?: string;
    projectGoal?: string;
  }>();
  const meQuery = useMeQuery();
  const [page, setPage] = useState(1);

  const where: Clients_Bool_Exp = {
    projects: {
      ...(params.teamMemberAccountId !== "all" && {
        project_team_members: {
          account: {
            id: { _eq: Number(params.teamMemberAccountId || meQuery.data?.me?.admin?.id) }
          }
        }
      }),
      ...(params.project && { stub: { _eq: params.project } }),
      id: { _is_null: false },
      archived: { _in: [false, Boolean(params.archived)] },
      ...((params.markets || params.verticals) && {
        campaigns: {
          _and: [
            ...(params.markets
              ? params.markets.split(",").map(m => ({ market_ids_string: { _like: `%|${m}|%` } }))
              : []),
            ...(params.verticals
              ? params.verticals
                  .split(",")
                  .map(v => ({ vertical_ids_string: { _like: `%|${v}|%` } }))
              : [])
          ]
        }
      })
    }
  };

  const { data: aggregateData } = useOverviewAggregateQuery({
    variables: { where }
  });
  const { data, loading, previousData } = useOverviewQuery({
    variables: {
      offset: (page - 1) * PER_PAGE,
      projectWhere: {
        ...(params.project && { stub: { _eq: params.project } }),
        archived: { _in: [false, Boolean(params.archived)] }
      },
      where
    }
  });

  const clients = data?.clients
    .map(c => ({
      ...c,
      projects: c.projects.filter(p => {
        if (params.platform) {
          if (!p.platforms?.includes(params.platform)) return false;
        }

        if (params.platform) {
          if (!p.platforms?.includes(params.platform)) return false;
        }

        if (params.projectGoal) {
          if (!p.goals?.includes(params.projectGoal)) return false;
        }

        return true;
      })
    }))
    .filter(c => c.projects.length);

  return (
    <ContentContainer>
      <OverviewHeader data={data || previousData} />
      {!loading ? (
        clients?.length ? (
          <>
            {clients.map(({ hubspot_company_id, name, projects }) => (
              <ClientCard
                key={hubspot_company_id}
                name={name || ""}
                projects={projects}
                activeProjectStub={params.project}
              />
            ))}
            {aggregateData && (
              <Pagination
                count={Math.round(
                  (aggregateData.clients_aggregate.aggregate?.count || PER_PAGE) / PER_PAGE
                )}
                page={page}
                onChange={(_, page) => setPage(page)}
              />
            )}
          </>
        ) : (
          <span>No projects match the filters</span>
        )
      ) : (
        <Loader rootClassName="loader" />
      )}
    </ContentContainer>
  );
};
